<template>
  <div
    class="auth-layout-wrap home"
    :style="{ backgroundImage: 'url(' + bgImage + ')' }"
  >
  <b-container>
    <b-row>
      <b-col class="text-center">
        <h1>
          Wij helpen jou met verstandig verduurzamen
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center m-1"> 
        <b-button @click="$router.push('/comfort-living/aanvragen')" class="choice">Doe mee!</b-button>
      </b-col>
      <b-col class="text-center m-1">
        <b-button @click="$router.push('/comfort-living/demo')" class="choice">Demo</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center">

        <router-link :to="{ name: 'personal' }">
          <b-button variant="info" size="lg" class="mt-5 font-weight-bold">Login om uw sensoren in te zien</b-button>
        </router-link>
      </b-col>

    </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Inloggen"
  },
  data() {
    return {
      email: "",
      password: "",
      userId: "",
      bgImage: require("@/assets/images/background-login.jpg"),
      logo: require("@/assets/images/logo-inverse.png")
    };
  },
  computed: {
    
  },

  methods: {
    
  },
  watch: {
    
  }
};
</script>

<style>
.spinner.sm {
  height: 2em;
  width: 2em;
}

.home {
  border-radius: 15px;
  margin-top:2em;
  background-position: center;
}

.choice {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  background:none;
  border:none;
  background: rgba(143, 188, 143, 0.8) !important;
  text-transform:uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  color: white !important;
}
</style>
